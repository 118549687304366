import { Box, Heading, Text, SimpleGrid } from "@chakra-ui/react";
import Form from "./Form";
import Navbar from "./Navbar";

function App() {
  const data = [
    {
      head: "Flurt Whitelist Benefits:",
      description: [
        "- Get a Custom Flurt NFT that looks like you.",
        "- Gain Premium Access to Flurt Network with our NFTs.",
        "- Get TIER 3 features for FREE in the soon to launch Flurt App.",
        "- NFT Holders get lifetime access to Flurt events, parties, bootcamps & more.",
      ],
    },
    {
      head: "How to join Whitelist?",
      description: [
        "1. Tweet about - How do you want your Flurt NFT to look like? or Talk about the Flurt within you.",
        "2. Tag @flurtnet on Twitter & use #imaflurt.",
        "3. Copy the URL to the said Tweet & submit it here.",
        "4. Fill up your details in the chat box here.",
      ],
    },
  ];
  return (
    <Box
      px="7%"
      h={["auto", "auto", "100vh", "110vh"]}
      bg="black"
      color="white"
      position="relative"
      overflow="hidden"
    >
      <Navbar />
      <Box
        w="1000px"
        h="1000px"
        position="absolute"
        opacity="0.3"
        left="-20"
        top="-36"
        bg="radial-gradient(41.76% 41.76% at 52.51% 51.69%, #A0506C 0%, rgba(191, 9, 255, 0) 100%)"
      />
      <SimpleGrid
        templateColumns={["auto", "auto", "55% 45%", "55% 45%"]}
        spacing="10"
        position="relative"
        h={["auto", "auto", "auto", "80vh"]}
        pb="14"
        mt="10"
      >
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Heading
            fontSize={["1.4em", "1.4em", "1.6em", "1.8em"]}
            bg="linear-gradient(67deg,#AA1240,#E94A81)"
            backgroundClip="text"
          >
            You're lucky to have found this web-page.
          </Heading>
          <Text fontWeight="900" fontSize="1.2em" mt="8" color="white">
            We will airdrop Rare Custom NFTs to selected Whitelisted Members.
          </Text>
          <Box>
            <Box>
              {data.map((item) => (
                <Box key={item.head}>
                  <Text
                    fontWeight="700"
                    fontSize={["md", "lg"]}
                    mt="4"
                    color="white"
                  >
                    Flurt Whitelist Benefits:
                  </Text>
                  {item.description.map((data) => (
                    <Text
                      fontSize={["md", "lg"]}
                      mt="2"
                      fontWeight="500"
                      key={data}
                    >
                      {data}
                    </Text>
                  ))}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            h={["80vh", "70vh", "70vh", "100%"]}
            position="relative"
            overflow="hidden"
            bg="black"
          >
            <Form />
          </Box>
        </Box>
      </SimpleGrid>
    </Box>
  );
}

export default App;
