import React from "react";
import { ConversationalForm } from "conversational-form";
import axios from "axios";

export default class MyForm extends React.Component {
  constructor(props) {
    super(props);
    this.formFields = [
      {
        tag: "input",
        type: "text",
        name: "fullname",
        "cf-questions": "Enter your full name. 👀",
        required: true,
      },
      {
        tag: "input",
        type: "text",
        name: "twitterUrl",
        "cf-questions": "Enter URL for the Twitter post. 🐦",
        required: true,
      },
      {
        tag: "input",
        type: "text",
        name: "maticSmartContractAddress",
        "cf-questions": "Enter Matic Smart Contract Address. 🌐",
        required: true,
      },
      {
        tag: "input",
        type: "email",
        name: "email",
        "cf-questions": "Enter your Email ID. 📨",
        required: true,
      },
      {
        tag: "input",
        type: "number",
        name: "phoneNo",
        "cf-questions": "Enter your phone number. 🤙",
        required: true,
      },
      {
        tag: "input",
        type: "text",
        name: "description",
        "cf-questions":
          "How do you want your Flurt Character to look like? Give us a brief description. (100 to 300 words only) 💫😏",
        required: true,
      },
    ];

    this.submitCallback = this.submitCallback.bind(this);
  }

  componentDidMount() {
    this.cf = ConversationalForm.startTheConversation({
      options: {
        theme: "dark",
        submitCallback: this.submitCallback,
        preventAutoFocus: true,
      },
      tags: this.formFields,
    });
    this.elem.appendChild(this.cf.el);
  }

  async submitCallback() {
    var formDataSerialized = this.cf.getFormData(true);
    this.cf.addRobotChatResponse(
      "Please wait while we are recording your response... 🙂⚡"
    );
    try {
      await axios.post(
        //"http://localhost:5000/nft_whitelist/add",
        "https://flurt-be.onrender.com/nft_whitelist/add",
        {
          data: formDataSerialized,
        }
      );
      this.cf.addRobotChatResponse(
        "You are done. We'll reach out to you soon. 😉"
      );
    } catch (err) {
      this.cf.addRobotChatResponse(
        "Sorry, failed to record data. Please reload and try again. 😟"
      );
    }
  }

  render() {
    return (
      <div>
        <div ref={(ref) => (this.elem = ref)} />
      </div>
    );
  }
}
