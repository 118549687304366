import { Box, Image } from "@chakra-ui/react";
import logo from "./utils/logo.png";

const Navbar = () => {
  return (
    <Box pt="3">
      <Image src={logo} w="14" alt="Flurt Logo" mt="10" />
    </Box>
  );
};

export default Navbar;
